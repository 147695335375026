import React, { useEffect, useRef } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import useModuleTheme from '@system/hooks/useModuleTheme'

const useStyles = makeStyles((theme) => ({
  moduleRoot: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
  },
  moduleThemeDark: {
    color: theme.palette.text.invert,
    '&::before': {
      backgroundColor: theme.palette.background.focus,
    },
  },
  moduleThemeLight: {
    color: theme.palette.text.default,
    '&::before': {
      backgroundColor: theme.palette.background.default,
    },
  },
  moduleThemeYellow: {
    color: theme.palette.text.default,
    '&::before': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  moduleThemeGrey: {
    color: theme.palette.text.default,
    '&::before': {
      backgroundColor: '#f4f6fc',
    },
  },
  moduleContent: {
    position: 'relative',
    zIndex: 5,
    '&::before': {
      content: '""',
      display: 'table',
    },
    '&::after': {
      content: '""',
      display: 'table',
    },
  },
}))

export type ModuleProps = DBN.IReactDefaultProps & {
  theme?: string
  anchor?: string
}

export default function Module({
  theme,
  anchor,
  children,
  className,
  ...props
}: ModuleProps): React.ReactElement {
  const classes = useStyles()
  const { changeTheme } = useModuleTheme()
  const moduleRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (moduleRef.current) {
        const moduleRect = moduleRef.current.getBoundingClientRect()
        if (moduleRect.top <= 0 && moduleRect.bottom > 0) {
          switch (theme) {
            case 'dark':
              changeTheme('dark')
              break
            // light + yellow + grey
            default:
              changeTheme('light')
          }
        }
      }
    }

    setTimeout(() => handleScroll(), 100)

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [moduleRef, changeTheme, theme])

  return (
    <div
      {...(anchor ? { id: anchor } : {})}
      className={clsx(className, classes.moduleRoot, {
        [classes.moduleThemeDark]: theme == 'dark',
        [classes.moduleThemeLight]: theme == 'light',
        [classes.moduleThemeYellow]: theme == 'yellow',
        [classes.moduleThemeGrey]: theme == 'grey',
      })}
      ref={moduleRef}
      {...props}
    >
      <div className={clsx('moduleContent', classes.moduleContent)}>
        {children}
      </div>
    </div>
  )
}
