import React, { ReactElement } from 'react'
import parse from 'html-react-parser'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  h2bg: {
    ...theme.typography.h2bg,
    [theme.breakpoints.up('md')]: {
      ...theme.typography.h2bg,
    },
  },
  '@global': {
    sup: {
      fontSize: '0.6em',
      lineHeight: 0.75,
    },
  },
}))

export type HeadlineProps = DBN.IReactDefaultProps & {
  level?: number
  id?: string
  html?: string
}

export default function Headline({
  className,
  children,
  level,
  id,
  html,
  ...props
}: HeadlineProps): ReactElement {
  const classes = useStyles()
  function renderChildren() {
    if (html) return null
    if (typeof children == 'string')
      return parse(
        children
          .replaceAll('  ', '<br>')
          .replaceAll('®', '<sup>®</sup>')
          .replaceAll('©', '<sup>©</sup>')
          .replaceAll('℗', '<sup>℗</sup>')
      )
    return children
  }
  function Level() {
    const Props = {
      id: id,
      ...(html && {
        dangerouslySetInnerHTML: {
          __html: html,
        },
      }),
      ...props,
    }
    switch (level) {
      case 21:
        return (
          <Typography
            variant={'h2'}
            className={clsx(className, classes.h2bg)}
            {...Props}
          >
            {renderChildren()}
          </Typography>
        )
      case 1:
        return (
          <Typography variant={'h1'} className={className} {...Props}>
            {renderChildren()}
          </Typography>
        )
      case 3:
        return (
          <Typography variant={'h3'} className={className} {...Props}>
            {renderChildren()}
          </Typography>
        )
      case 4:
        return (
          <Typography variant={'h4'} className={className} {...Props}>
            {renderChildren()}
          </Typography>
        )
      case 5:
        return (
          <Typography variant={'h5'} className={className} {...Props}>
            {renderChildren()}
          </Typography>
        )
      default:
        return (
          <Typography variant={'h2'} className={className} {...Props}>
            {renderChildren()}
          </Typography>
        )
    }
  }
  return <>{Level()}</>
}
