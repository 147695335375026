import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@components/modules/global/container'
import Link from '@components/core/link'
import Headline from '@components/text/headline'
import Paragraph from '@components/text/paragraph'
import { Theme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import Module from '@components/core/module'
import useGlobalText from '@system/hooks/useGlobalText'
import FontSize from '@config/theme/definitions/fontSize'
import { defaultLang } from '@system/translations'

const useStyles = makeStyles(
  (theme: Theme): Record<string, CSSProperties> => ({
    pageRoot: {
      background: theme.palette.background.focus,
      color: theme.palette.text.invert,
      marginTop: theme.spacing(0) + '!important',
      paddingTop: theme.spacing(18),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(18),
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        minHeight: 'calc(100vh - 140px)',
      },
    },

    headline: {
      fontSize: FontSize['5xl'],
      lineHeight: '56px',
    },

    containerWrapper: {
      display: 'block',
      width: '100vw',
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(18),
      [theme.breakpoints.up('md')]: {
        minHeight: '600px',
        paddingTop: 262,
        paddingBottom: theme.spacing(18),
      },
    },
    bigNumber: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      fontSize: '217px',
      color: '#ffed00',
      overflow: 'hidden',
      lineHeight: 0.8,
      paddingTop: theme.spacing(46),
      paddingBottom: theme.spacing(16),
      position: 'relative',
      // height: '292px',
      '&>div': {
        position: 'relative',
        textAlign: 'right',
        top: '27px',
        right: '-24px',
        height: '145px',
        overflow: 'hidden',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '285px',
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        top: '-70px',
        '&>div': {
          fontSize: '450px',
          top: '0px',
          right: '-70px',
          height: '295px',
        },
      },
    },
    copy: {
      marginBottom: theme.spacing(4),
    },
    button: {
      paddingLeft: 0,
      fontFamily: theme.typography.fontFamily,
      fontSize: FontSize['lg'],
      lineHeight: 1.11111,
    },
  })
)

export type NotFoundProps = {
  pageContext: DBN.PageHelpers.PageContext
}

export default function NotFound({ pageContext }: NotFoundProps): ReactElement {
  const classes = useStyles()
  const { getText } = useGlobalText()
  const homeLink = pageContext.locale
    ? pageContext.locale === defaultLang
      ? `/`
      : `/${pageContext.locale}/`
    : '/'

  return (
    <Module theme="dark" className={classes.pageRoot}>
      <div className={classes.containerWrapper}>
        <Container className={classes.container} type="nomargin">
          <Headline className={classes.headline} level={1}>
            {getText('page.404.headline')}
          </Headline>
        </Container>
        <div className={classes.bigNumber}>
          <div>404</div>
        </div>
        <Container className={classes.container} type="nomargin">
          <Paragraph className={classes.copy}>
            {getText('page.404.copy')}
          </Paragraph>
          <Link
            to={homeLink}
            isExternal={false}
            icon={{ name: 'ArrowTeaser', size: 'small' }}
            className={classes.button}
          >
            {getText('page.404.cta')}
          </Link>
        </Container>
      </div>
    </Module>
  )
}
